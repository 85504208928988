import {Component} from '@angular/core'
import {CommonModule} from '@angular/common'
import {DynamicDialogRef} from "primeng/dynamicdialog"
import {ButtonModule} from "primeng/button"

@Component({
  selector: 'frontend-map-footer',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
  ],
  templateUrl: './map-footer.component.html',
  styleUrl: './map-footer.component.sass',
})
export class MapFooterComponent {
  constructor(private ref: DynamicDialogRef) {
  }

  close() {
    this.ref.close()
  }
}
