import {ElementRef} from "@angular/core"

export const HOME_LAT= 47.1674397
export const HOME_LNG= 8.2886437

export enum MapType {
  PLACES = 'PLACES',
  ROUTES = 'ROUTES',
}

export interface MapConfig {
  htmlRef: ElementRef
  origin: string | google.maps.LatLng | google.maps.Place | google.maps.LatLngLiteral
  destination: string | google.maps.LatLng | google.maps.Place | google.maps.LatLngLiteral
  departureTime?: Date | string | undefined,
}

export interface MapConfig0 {
  htmlRef: ElementRef
  fromLat: number
  fromLng: number
  toLat?: number
  toLng?: number
}

export interface MapSearchResult {
  status: string
  error_message?: string
  formatted_address?: string
  place_id?: string
  lat?: number
  lng?: number
}

export interface GeoDirectionsRequest {
  origin: string
  destination: string
  departureAt?: string
}

export interface GeoDirectionsResult {
  distance: number
  duration: number
  origin: GeoDirectionsPlace
  destination: GeoDirectionsPlace
}

export interface GeoDirectionsPlace {
  placeId: string
  lat: number
  lng: number
}
