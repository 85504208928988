import {Component, ElementRef, OnInit, ViewChild} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ButtonModule} from "primeng/button"
import {MapService} from "../../../../services/src/lib/map.service"
import {logger} from "nx/src/utils/logger"
import {MapConfig, MapType} from "../../../../domain/src/lib/map.model"
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog"
import {DialogModule} from "primeng/dialog"
import {CardModule} from "primeng/card"

@Component({
  selector: 'map-route',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    CardModule,
  ],
  templateUrl: './map.component.html',
  styleUrl: './map.component.sass',
})
export class MapComponent implements OnInit {
  @ViewChild('map', {static: true}) mapContainer!: ElementRef
  // private mapType = MapType.PLACES
  mapType = MapType.ROUTES
  departureAddress = "strasse 1, location1"
  arrivalAddress = "strasse 2, location2"

  constructor(
      private mapSVC: MapService,
      private ref: DynamicDialogRef,
      private config: DynamicDialogConfig,
  ) {
  }

  ngOnInit() {
    // this.loadMap(this.mapType, this.config.data)
    this.config.data.htmlRef = this.mapContainer
    this.mapSVC.initMap(this.mapType, this.config.data)

  }

  closeDialog() {
    this.ref.close()
  }

  /*
  private loadMap(mapType: MapType, config: MapConfig) {
    this.mapSVC.loadMap()?.then(() => {
      switch (mapType) {
        case MapType.PLACES:
          this.initPlacesMap(
              // {lat: 47.167541, lng: 8.292419},
              {lat: config.fromLat, lng: config.fromLng},
          )
          break
        case MapType.ROUTES:
          this.initRoutesMap(
              // {lat: 47.167541, lng: 8.292419},
              // lat: this.config.data.selectedRide.departureLat,
              {lat: config.fromLat, lng: config.fromLng},
              {
                lat: config.toLat ? config.toLat : config.fromLat,
                lng: config?.toLng ? config.toLng : config.fromLng,
              },
          )
          break
        default:
          logger.error(`Not yet implemented MapType: ${mapType}`)
          break
      }
    })
  }
   */

  /*
  private initQueryMap(query: string) {
    const request = {
      // query: query,
      query: 'Bahnhofplatz, 6280 Hochdorf',   // test
      fields: ['geometry'],
    }
    const map = new google.maps.Map(
        this.mapContainer.nativeElement,
    )

    // const service = new google.maps.places.PlacesService(map)
    // service.findPlaceFromQuery(request, function (results, status) {
    //   if (status === google.maps.places.PlacesServiceStatus.OK) {
    //     if (results) {
    //       results.forEach(value => {
    //         logger.debug('map#init-query-map: result=', value)
    //       })
    //     }
    //   }
    // })
  }
   */

  /*
  private initRoutesMap(
      origin: google.maps.LatLngLiteral,
      destination: google.maps.LatLngLiteral,
      departureTime = new Date(),
      avoidHighways = false,
  ) {
    const mapOptions: google.maps.MapOptions = {}

    const drivingOptions: google.maps.DrivingOptions = {
      departureTime: departureTime ? departureTime : new Date(),
    }
    const request: google.maps.DirectionsRequest = {
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING,
      drivingOptions: drivingOptions,
      unitSystem: google.maps.UnitSystem.METRIC,
      optimizeWaypoints: true,
      provideRouteAlternatives: true,
      avoidHighways: avoidHighways,
      region: 'ch',
    }

    const map = new google.maps.Map(
        this.mapContainer.nativeElement,
        mapOptions,
    )

    const service = new google.maps.DirectionsService()
    const renderer = new google.maps.DirectionsRenderer()

    renderer.setMap(map)
    service.route(request)
           .then((response) => {
             renderer.setDirections(response)
           })
           .catch((e) => {
             window.alert(`directions request failed: ${e.message}`)
           })
  }
   */

  /*
  private initPlacesMap(
      center: google.maps.LatLngLiteral,
      zoom: number = 13,  // places
  ) {
    const mapOptions: google.maps.MapOptions = {
      center: center,
      zoom: zoom,
    }

    const map = new google.maps.Map(
        this.mapContainer.nativeElement,
        mapOptions,
    )
  }
   */

}
