<p-card>
  <div #map class="map"></div>

  <ng-template pTemplate="footer">
    <div class="row" style="justify-content: flex-end;">
      <p-button label="Schliessen" icon="pi pi-map"
                (onClick)="closeDialog()"
                severity="success">
      </p-button>
    </div>
  </ng-template>
</p-card>
