import { environment } from '@env'
import { ElementRef, Injectable } from '@angular/core'
import { logger } from 'nx/src/utils/logger'
import { Library, Loader, LoaderOptions } from '@googlemaps/js-api-loader'
import { MapConfig, MapSearchResult, MapType, GeoDirectionsRequest } from '@domain/map'
import { map, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'

// import Place = google.maps.places.Place

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private apiKey!: string
  private libraries = ['routes']
  private version = 'weekly'
  private loader!: Loader
  private response!: google.maps.DirectionsResult

  constructor(
    private http: HttpClient
  ) {
    this.apiKey = environment.gmaAPIKey
    const loaderOptions: LoaderOptions = {
      apiKey: this.apiKey,
      version: this.version,
      libraries: this.libraries as Library[]
    }
    this.loader = new Loader(loaderOptions)
  }

  initMap(mapType: MapType, config: MapConfig) {
    console.debug('mapService#initMap: config=', config)
    this.loadMap()?.then(() => {
      switch (mapType) {
        case MapType.PLACES:
          this.initPlacesMap(
            config.htmlRef,
            { lat: 47.167541, lng: 8.292419 }
          )
          break
        case MapType.ROUTES:
          this.initRoutesMap(
            config.htmlRef,
            config.origin,
            config.destination,
            config.departureTime
          )
          break
        default:
          logger.error(`Not yet implemented MapType: ${mapType}`)
          break
      }
    })
  }

  findPlace(query: string): Observable<MapSearchResult> {
    // curl -L -X GET 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=Musem%20of%20Contemporary%20Art%20Australia&inputtype=textquery&fields=place_id,geometry,formatted_address&key=AIzaSyCtqjzNjMTTMlWUvOO-bGQHI1bAFYD3OqA'
    const url = `${environment.apiUrl}/maps/find`
    // const url = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${htmlQuery}&inputtype=textquery&fields=place_id,geometry,formatted_address&key=${apiKey}`
    // const url: string = 'http://localhost:4200/api/v1/maps/find'
    const htmlQuery = 'Musem%20of%20Contemporary%20Art%20Australia'
    const apiKey = this.apiKey
    // const encUrl = this.encodeURL(url, query)
    // const encUrl = `${url}/${htmlQuery}`
    const encUrl = `${url}/${query}`
    console.debug('mapService#findPlace: query=', query)
    console.debug('mapService#findPlace: url=', url)
    console.debug('mapService#findPlace: encUrl=', encUrl)
    // return this.http.get<MapSearchResult>(encUrl)
    // const res = this.http.get<MapSearchResult>(encUrl).pipe(
    // const res = this.http.get<any>(encUrl).pipe(
    return this.http.get<MapSearchResult>(encUrl).pipe(
      map(value => {
        console.debug('mapService#findPlace: value=', value)
        if (value?.formatted_address) {
          value.formatted_address = value.formatted_address.replace(', Switzerland', '')
        }
        return value
      })
    )
    // console.debug('mapService#findPlace: res=', res)
  }

  // TODO: convert to prototype
  private convertToDate(dateTime: Date | string | undefined): Date {
    let res = new Date()
    switch (typeof dateTime) {
      case 'string': {
        res = new Date(dateTime)
        break
      }
      case 'undefined': {
        res = new Date()
        break
      }
      default: {
        res = new Date()
        break
      }
    }
    const now = new Date()
    return (res < now) ? now : res
  }

  private initRoutesMap(
    htmlRef: ElementRef,
    origin: string | google.maps.LatLng | google.maps.Place | google.maps.LatLngLiteral,
    destination: string | google.maps.LatLng | google.maps.Place | google.maps.LatLngLiteral,
    departureTime: Date | string | undefined,
    avoidHighways = false
  ): google.maps.DirectionsResult {
    const mapOptions: google.maps.MapOptions = {
      center: { lat: 47.1674397, lng: 8.2886437 },
      zoom: 20,
      streetViewControl: false
    }

    const drivingOptions: google.maps.DrivingOptions = {
      departureTime: this.convertToDate(departureTime)
    }
    const request: google.maps.DirectionsRequest = {
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING,
      drivingOptions: drivingOptions,
      unitSystem: google.maps.UnitSystem.METRIC,
      optimizeWaypoints: true,
      provideRouteAlternatives: true,
      avoidHighways: avoidHighways,
      language: 'de',
      region: 'ch'
    }

    logger.debug('mapService#initRoutesMap: htmlRef=', htmlRef)
    const map = new google.maps.Map(
      htmlRef.nativeElement,
      mapOptions
    )

    const service = new google.maps.DirectionsService()
    const renderer = new google.maps.DirectionsRenderer()

    renderer.setMap(map)
    service.route(request)
           .then((response) => {
             logger.debug('map-service#route: response=', response)
             logger.debug('map-service#route: routes=', response.routes)
             this.response = response
             renderer.setDirections(response)
           })
           .catch((e) => {
             window.alert(`directions request failed: ${e.message}`)
           })
    return this.response
  }

  private initPlacesMap(
    htmlRef: ElementRef,
    center: google.maps.LatLngLiteral,
    zoom: number = 13  // places
  ) {
    const mapOptions: google.maps.MapOptions = {
      center: center,
      zoom: zoom
    }

    const map = new google.maps.Map(
      htmlRef.nativeElement,
      mapOptions
    )
  }

  private loadMap() {
    if (this.loader === undefined) {
      console.error('Loader is not initialized!')
      return
    }
    return this.loader.load()
  }

  private encodeURL(url: string, query: string): string {
    const encodedQuery: string = encodeURIComponent(query)
    const encodedURL: string = `${url}/${encodedQuery}`
    console.debug('mapService#encodeURL: encodedUrl=', encodedURL)
    return encodedURL
  }

  /*
   findPlace(query: string): Observable<MapSearchResult[]> {
   const url = `${environment.apiUrl}/map/find`
   return this.http.get<MapSearchResult[]>(url).pipe(
   map(values => {
   return values
   }),
   )
   }
   */

  /*
   async queryPlace(query: string) {
   const request = {
   textQuery: query,
   fields: ['location'],
   // locationBias: { lat: 37.4161493, lng: -122.0812166 },
   isOpenNow: true,
   language: 'de-CH',
   maxResultCount: 1,
   minRating: 3.2,
   region: 'ch',
   useStrictTypeFiltering: false,
   }


   const {places} = await google.maps.places.Place.searchByText(request)
   return places
   }
   */

  /*
   queryPlace(container: ElementRef, query: string):any {
   const request = {
   // query: query,
   query: 'Bahnhofplatz, 6280 Hochdorf',   // test
   fields: ['geometry'],
   }

   const map = new google.maps.Map(container.nativeElement)
   const service = new google.maps.places.PlacesService(map)

   service.findPlaceFromQuery(request, function (results, status) {
   if (status === google.maps.places.PlacesServiceStatus.OK) {
   if (results) {
   results.forEach(value => {
   logger.debug('map#init-query-map: result=', value)
   })
   return results
   }
   } else {
   logger.error(`findPlaceFromQuery failed, status=${status}`)
   }
   return null
   })
   }
   */


  formatAddress(address: string): Observable<string> {
    const url = `${environment.apiUrl}/geocodings/format-address/${address}`
    logger.debug('geo-api-service#format-address: url=', url)
    return this.http.get(url, { responseType: 'text' })
  }

  coordinates(origin: string, destination: string, departureAt?: string): Observable<any> {
    const url = `${environment.apiUrl}/geocodings/coordinates`
    logger.debug('geo-api-service#coordinates: url=', url)
    logger.debug('geo-api-service#coordinates: departureAt=', departureAt)
    const request = {
      origin: origin,
      destination: destination,
      departureAt: departureAt
    } as GeoDirectionsRequest
    return this.http.post(url, request).pipe(
      map(value => {
        return value
      })
    )
  }

  place(address: string): Observable<any> {
    const url = `${environment.apiUrl}/geocodings/place/${address}`
    logger.debug('geo-api-service#place: url=', url)
    return this.http.get(url).pipe(
      map(value => {
        return value
      })
    )
  }
}


